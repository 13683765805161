import "bootstrap/dist/js/bootstrap.js";
import { useContext, useEffect } from "react";
import Modal from "react-modal";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import QuickMenu from "./components/QuickMenu";
import SessionContext from "./context/SessionContext";
import "./css/bootstrap.min.css";
import "./css/fontSizing.css";
import "./css/darkmode.css";
import useServices from "./hooks/useServices";
import { MODULOS_HAB } from "./lib/modulesHab";
import About from "./pages/About";
import AforosBYMA from "./pages/AforosBYMA";
import ControlNominales from "./pages/ControlNominales";
import ControlSaldosAUNE from "./pages/ControlSaldosAUNE";
import Daytrading from "./pages/Daytrading";
import Distribuciones from "./pages/Distribuciones";
import DistribucionesCaucionBYMA from "./pages/DistribucionesCaucionBYMA";
import DistribucionesMAV from "./pages/DistribucionesMAV";
import Ejercicios from "./pages/Ejercicios";
import EjerciciosTrader from "./pages/EjerciciosTrader";
import Feriados from "./pages/Feriados";
import Fondos from "./pages/Fondos";
import ListaComitentes from "./pages/ListaComitentes";
import Login from "./pages/Login";
import MarketSesions from "./pages/MarketSesions";
import Menu from "./pages/Menu";
import Nominales from "./pages/Nominales";
import NominalesMAV from "./pages/NominalesMAV";
import Panel from "./pages/Panel";
import PanelAP5 from "./pages/PanelAP5";
import PanelAP5Trader from "./pages/PanelAP5Trader";
import PanelDerivaciones from "./pages/PanelDerivaciones";
import PanelDerivacionesMAV from "./pages/PanelDerivacionesMAV";
import PanelMAV from "./pages/PanelMAV";
import PanelSB from "./pages/PanelSB";
import PanelTrader from "./pages/PanelTrader";
import PosiOpc from "./pages/PosiOpc";
import PosiOpcTrader from "./pages/PosiOpcTrader";
import ResumenSaldosMdos from "./pages/ResumenSaldosMdos";
import RG624 from "./pages/RG624";
import SaldosBOP from "./pages/SaldosBOP";
import Sdib from "./pages/Sdib";
import UsersPanel from "./pages/UsersPanel";
import Vc from "./pages/Vc";
import VencCauc from "./pages/VencCauc";
import ControlSaldosVB from "./pages/ControlSaldosVB";
import VencCaucTrader from "./pages/VencCaucTrader";
import VcTrader from "./pages/VcTrader";
import NeteoCaucTrader from "./pages/NeteoCaucTrader";
import PosicionesMTR from "./pages/PosicionesMTR";
import PosicionesMTRTrader from "./pages/PosicionesMTRTrader";
import GarantiasYMargenesMTR from "./pages/GarantiasYMargenesMTR";
import GarantiasYMargenesMTRTrader from "./pages/GarantiasYMargenesMTRTrader";
import TablaSizesMTR from "./pages/TablaSizesMTR";
import GarantiasYCoberturasBYMA from "./pages/GarantiasYCoberturasBYMA";
import GarantiasYCoberturasBYMATrader from "./pages/GarantiasYCoberturasBYMATrader";
import ConciliaTenenciaCV from "./pages/ConciliaTenenciaCV";
import ConciliaOpersVB from "./pages/ConciliaOpersVB";
import NominalesFCI from "./pages/NominalesFCI";
import ControlSaldosAUNEAN from "./pages/ControlSaldosAUNEAN";
import ControlNominalesAUNEAN from "./pages/ControlNominalesAUNEAN";
import PanelOperacionesMesa from "./pages/PanelOperacionesMesa";
import CanceladasMTR from "./pages/CanceladasMTR";
import CanceladasMTRTrader from "./pages/CanceladasMTRTrader";
import CargaSaldosTrader from "./pages/CargaSaldosTrader";
import PanelOperaciionesAN from "./pages/PanelOperacionesAN";
import PanelSBAN from "./pages/PanelSBAN";
import VencCaucAN from "./pages/VencCaucAN";
import PosiOpcAN from "./pages/PosiOpcAN";
import PanelMAVAN from "./pages/PanelMAVAN";
import ControlSaldosPerfilAN from "./pages/ControlSaldosPerfilAN";
import ControlNominalesPerfilAN from "./pages/ControlNominalesPerfilAN";
import LoginAdmin from "./pages/LoginAdmin";



const hasDerivaciones = process.env.REACT_APP_BYMA_DERIVACIONES === "true";
const PANEL_OPERACIONES_MESA = process.env.REACT_APP_MENU_PANEL_OPERACIONES_MESA === "true"; 
const REACT_APP_INSTANCIA_AN = process.env.REACT_APP_INSTANCIA_AN === "true";

Modal.setAppElement("#root");

function App() {
  const context = useContext(SessionContext);
  const { tkn, setTkn, setUserCategory } = context;
  const tknSessionStorage = window.localStorage.getItem("tkn");
  const userCategorySessionStorage = window.localStorage.getItem("userCategory");
 
  
  //console.log({ tknSessionStorage });
  if (tknSessionStorage) {
    setTkn(tknSessionStorage);
    setUserCategory(userCategorySessionStorage);
  }
  const isLoged = tkn !== "" && tkn != null && tkn !== "null";
  const {userStatus} = useServices();
  async function handleUserStaus() {
  const res = await userStatus();
  if(res.error) return
  }

 function checkDarkMode () {
  if(window.localStorage.getItem("darkmode")) {document.documentElement.classList.toggle("dark-mode"); console.log("entre en darkmode")} 
  }

  useEffect(() => {
    checkDarkMode();
   if(tkn){ handleUserStaus();}

  }, [tkn])
  


  //console.log({ MODULOS_HAB });

  return (
    <Router>
      <Switch>
        {!isLoged && (
          <>
            <Route exact path="/">
              <Login />
            </Route>
            <Route exact path="/login">
              <Login />
            </Route>
            <Route exact path="/menu">
              <Login />
            </Route>
            <Route exact path="/about">
              <About />
            </Route>
            <Route exact path="/adm">
            <LoginAdmin />
            </Route>

          </>
        )}
        {isLoged && (
          <>
            <Route exact path="/">
              <Menu />
            </Route>

            <Route path="/login">
              <Login />
            </Route>
            <Route path="/menu">
              <Menu />
            </Route>
            {PANEL_OPERACIONES_MESA ? (
              <Route path="/panel">
                <PanelOperacionesMesa />
              </Route>
            ) : (
              <Route path="/panel">
                <Panel />
              </Route>
            )}

            <Route path="/panelSB">
              <PanelSB />
            </Route>
            <Route path="/panelMAV">
              <PanelMAV />
            </Route>
            <Route path="/panelDerivacionesMAV">
              <PanelDerivacionesMAV />
            </Route>
            <Route path="/distribucionesMAV">
              <DistribucionesMAV />
            </Route>

            <Route path="/distribucionesBYMA">
              <Distribuciones />
            </Route>
            <Route path="/distribucionesCaucionBYMA">
              <DistribucionesCaucionBYMA />
            </Route>
            <Route path="/conciliaTenenciaCV">
              <ConciliaTenenciaCV />
            </Route>
            <Route path="/conciliaOpersVB">
              <ConciliaOpersVB />
            </Route>

            <Route path="/controlSaldosVB">
              <ControlSaldosVB />
            </Route>

            <Route path="/nominales">
              <Nominales />
            </Route>

            <Route path="/nominalesFCI">
              <NominalesFCI />
            </Route>

          <Route path="/cargaSaldosTrader">
              <CargaSaldosTrader />
            </Route>
            <Route path="/nominalesMAV">
              <NominalesMAV />
            </Route>

            <Route path="/resumenSaldosMdos">
              <ResumenSaldosMdos />
            </Route>

            <Route path="/fondos">
              <Fondos />
            </Route>

            <Route path="/aforosBYMA">
              <AforosBYMA />
            </Route>

            <Route path="/posicauc">
              <VencCauc />
            </Route>
            {REACT_APP_INSTANCIA_AN && 
            <>
            <Route path="/panelAN">
              <PanelOperaciionesAN />
            </Route>
            <Route path="/panelSBAN">
              <PanelSBAN /> 
              </Route>
              <Route path="/venCaucAN">
              <VencCaucAN />
              </Route>
              <Route path="/posiOpcAN">
              <PosiOpcAN />
              </Route>
              <Route path="/panelMAVAN">
                  <PanelMAVAN />
                </Route>
                <Route path="/controlSaldosAN">
                  <ControlSaldosPerfilAN />
                </Route>
                <Route path="/controlNominalesAN">
                  <ControlNominalesPerfilAN />
                </Route>
            </>
            }

            
            <Route path="/posicaucTrader">
              <VencCaucTrader />
            </Route>

            <Route path="/neteoCaucTrader">
              <NeteoCaucTrader />
            </Route>

            <Route path="/garantiasYCoberturasBYMA">
              <GarantiasYCoberturasBYMA />
            </Route>
            <Route path="/garantiasYCoberturasBYMATrader">
              <GarantiasYCoberturasBYMATrader />
            </Route>
            <Route path="/controlSaldosAUNEAN">
              <ControlSaldosAUNEAN />
            </Route>

            <Route path="/controlNominalesAUNEAN">
              <ControlNominalesAUNEAN />
            </Route>

            <Route path="/posiopc">
              <PosiOpc />
            </Route>
            {MODULOS_HAB.SDIB && (
              <Route path="/sdib">
                <Sdib />
              </Route>
            )}
            <Route path="/comitentes">
              <ListaComitentes />
            </Route>
            {MODULOS_HAB.RG624 && (
              <Route path="/rg624">
                <RG624 />
              </Route>
            )}
            <Route path="/daytrading">
              <Daytrading />
            </Route>
            <Route path="/feriados">
              <Feriados />
            </Route>
            {MODULOS_HAB.SALDOS_BOP && (
              <>
                <Route path="/saldosbop">
                  <SaldosBOP />
                </Route>
                <Route path="/controlSaldosAUNE">
                  <ControlSaldosAUNE />
                </Route>
              </>
            )}
            {MODULOS_HAB.CONTROL_NOMINALES && (
              <Route path="/controlnominales">
                <ControlNominales />
              </Route>
            )}
            <Route path="/ejercicios">
              <Ejercicios />
            </Route>

            {MODULOS_HAB.VC && (
              <>
                <Route path="/vc">
                  <Vc />
                </Route>
                <Route path="/vcTrader">
                  <VcTrader />
                </Route>
              </>
            )}
            {MODULOS_HAB.OPERACIONES_MTR && (
              <Route path="/panelAP5">
                <PanelAP5 />
              </Route>

            )}
            <Route path="/canceladasMTR">
              <CanceladasMTR />
            </Route>
            <Route path="/panelAP5Trader">
              <PanelAP5Trader />
            </Route>
            <Route path="/posicionesMTR">
              <PosicionesMTR />
            </Route>
            <Route path="/posicionesMTRTrader">
              <PosicionesMTRTrader />
            </Route>
            <Route path="/canceladasMTRTrader">
            <CanceladasMTRTrader />
            </Route>
            <Route path="/tablaSizesMTR">
              <TablaSizesMTR />
            </Route>

            <Route path="/garantiasYMargenesMTR">
              <GarantiasYMargenesMTR />
            </Route>
            <Route path="/garantiasYMargenesMTRTrader">
              <GarantiasYMargenesMTRTrader />
            </Route>

            <Route path="/usersPanel">
              <UsersPanel />
            </Route>

            <Route path="/marketSesions">
              <MarketSesions />
            </Route>
            <Route exact path="/about">
              <About />
            </Route>

            {hasDerivaciones && (
              <Route path="/panelDerivaciones">
                <PanelDerivaciones />
              </Route>
            )}

            <Route path="/panelTrader">
              <PanelTrader />
            </Route>
            <Route path="/posiOpcTrader">
              <PosiOpcTrader />
            </Route>
            <Route path="/ejerciciosTrader">
              <EjerciciosTrader />
            </Route>
          </>
        )}
        <Route path="*">
          <Login />
        </Route>
      </Switch>

      <QuickMenu />
    </Router>
  );
}

export default App;
